*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
